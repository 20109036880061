import {useEffect, useState} from 'react'
import {Link, useMatch} from 'react-router-dom'
import {collection, onSnapshot, query, orderBy, getDocs, where, doc, deleteDoc, addDoc} from 'firebase/firestore'
import {db} from './../firebase/firebase'
import _ from 'lodash'

import PageHeadline from './PageHeadline.js'
import AreaSingleListSingle from './AreaSingleListSingle.js'


function AreaSingle(props) {
	const [projects, setProjects] = useState([]);
 	const [areaId, setAreaId] = useState();
	const [areaName, setAreaName] = useState();
	const [input, setInput] = useState('');
	const [renameActive, setRenameActive] = useState(false)
	const [taskname, setTaskname] = useState()

	let match = useMatch(':areaSlug');

	useEffect(() => {
		let areaId;

		const fetchArea = async () => {
			const areaQ = query(collection(db, 'areas'), where('slug', '==', match.params.areaSlug))
			const querySnapshop = await getDocs(areaQ);
			querySnapshop.forEach(doc => {
				areaId = doc.id;
				setAreaId(areaId);
				setAreaName(doc.data().name);
			})

			const q = query(collection(db, `areas/${areaId}/projects`))
			const unsub = onSnapshot(q, async snapshot => {
			  	const list = []
			  	snapshot.forEach(ele => {
			    	list.push({
			    		id: ele.id,
			    		name: ele.data().name,
			    		slug: ele.data().slug
			    	})
			  	})

			  	await Promise.all(list.map(async (ele, i) => {
			  		let subAreaProjects = []
			  		const subAreaQ = query(collection(db, `areas/${areaId}/projects/${ele.id}/lists`))
			  		const subAreaSnapshot = await getDocs(subAreaQ);
			  		subAreaSnapshot.forEach(doc => {
			  			subAreaProjects.push(doc.data())
			  		})
			  		list[i].subAreaProjects = subAreaProjects
			  	}))

			  	setProjects(_.orderBy(list, [item => item.name.toLowerCase()], ['asc']));
			});
		}

		fetchArea();

	}, [])

	const addItem = async () => {
		// create project
			const projectData = {
				name: input, 
				slug: input.replace(/\s+/g, '')
			}
			const colRefProjects = collection(db, `areas/${areaId}/projects`);
			const projectRef = await addDoc(colRefProjects, projectData);
			

		// write initial data to projects
			const listData = {
				name: 'log', 
				type: 'log', 
				showTasks: true
			}
			const colRefLists = collection(db, `areas/${areaId}/projects/${projectRef.id}/lists`)
			const listRef = await addDoc(colRefLists, listData);

		
		// reset input
			setInput('')
	}

	return (
		<div id="AreaSingle">
			<PageHeadline title={areaName}/>
			<div className="projectlist-wrapper">
				{projects.map((ele) => {
					return(
					<AreaSingleListSingle key={ele.id} data={ele} areaId={areaId}/>
				)})}
				<div className="addnew-wrapper">
	              <input placeholder="+ Tilføj musikskole" className="addnew-input" value={input} type="text" onChange={e => setInput(e.target.value)} onKeyPress={(e) => {if (e.key == 'Enter') addItem()}}/>
	            </div>
			</div>
		</div>
		)
}

export default AreaSingle