import { initializeApp } from 'firebase/app';
import {getFirestore} from 'firebase/firestore'

// test
  // const firebaseConfig = {
  //   apiKey: "AIzaSyBT3tE75kml2xjX0vRULqtXG0DdiujvPIY",
  //   authDomain: "lms-test-69f70.firebaseapp.com",
  //   projectId: "lms-test-69f70",
  //   storageBucket: "lms-test-69f70.appspot.com",
  //   messagingSenderId: "1028998362082",
  //   appId: "1:1028998362082:web:12f97a9ae381722d297fbb"
  // };

// live
  const firebaseConfig = {
    apiKey: "AIzaSyDK4OJKHPCRXlyBjfMNvUHoG768MpTffWM",
    authDomain: "lms-project-4863f.firebaseapp.com",
    projectId: "lms-project-4863f",
    storageBucket: "lms-project-4863f.appspot.com",
    messagingSenderId: "537895621657",
    appId: "1:537895621657:web:f624a976aea6a57c130688"
  };

const firebaseApp = initializeApp(firebaseConfig);

export const db = getFirestore(firebaseApp)