import {useState} from 'react'
import {Link} from 'react-router-dom'
import {doc, deleteDoc, updateDoc, getDocs, writeBatch, collection} from 'firebase/firestore'
import {db} from './../firebase/firebase'


const AreaSingleListSingle = ({data, areaId}) => {
	const [taskname, setTaskname] = useState()
	const [renameActive, setRenameActive] = useState(false)

	const deleteItem = async (e) => {
		e.preventDefault();
		if (window.confirm('Slet "'+data.name+'"?')) {
			
			// delete tasks	
				let lists = []
				const listsBatch = writeBatch(db)
				const listsRef = collection(db, `areas/${areaId}/projects/${data.id}/lists`);
				const listsDocs = await getDocs(listsRef)
				listsDocs.forEach(listSnapshot => {
					lists.push(listSnapshot.id)
					/*
					// const tasksBatch = writeBatch(db);
					const tasksRef = collection(db, `areas/${areaId}/projects/${data.id}/lists/${listSnapshot.id}/tasks`);
					const tasksDocs = await getDocs(tasksRef)
					console.log('after');
					console.log(tasksDocs);

					tasksDocs.forEach(taskSnapshot => {
						console.log(taskSnapshot);
						const taskDoc = doc(db, `areas/${areaId}/projects/${data.id}/lists/${listSnapshot.id}`)
						console.log(taskDoc);
						// tasksBatch.delete(taskDoc)
					})
					// tasksBatch.commit()

					*/
					const listDoc = doc(db, `areas/${areaId}/projects/${data.id}/lists/${listSnapshot.id}`)
					listsBatch.delete(listDoc)

				})
				console.log(lists);

				Promise.all(lists.map(async singleList => {
					const tasksBatch = writeBatch(db);

					const tasksRef = collection(db, `areas/${areaId}/projects/${data.id}/lists/${singleList}/tasks`);
					const tasksDocs = await getDocs(tasksRef)
					tasksDocs.forEach(singleTask => {
						// console.log(singleTask.id);
						const taskDoc = doc(db, `areas/${areaId}/projects/${data.id}/lists/${singleList}/tasks/${singleTask.id}`)
						tasksBatch.delete(taskDoc)
					})
					tasksBatch.commit()
				}))

				listsBatch.commit();
			
			// delete lists

			// delete subarea
				const docRef = doc(db, `areas/${areaId}/projects/${data.id}`)
				await deleteDoc(docRef);
		}
	}

	const activateRenameItem = (e, ele) => {
		e.preventDefault();
		setTaskname(ele.name)
		setRenameActive(true);
	}

	const updateItemName = () => {
		const docRef = doc(db, `areas/${areaId}/projects/${data.id}`)
		updateDoc(docRef, {
			name: taskname
		})
		setTimeout(() => {
			setRenameActive(false)
		},100)
	}

	return (
		<Link to={data.slug} onClick={(e) => {if (renameActive) e.preventDefault()}}>
			<div className="AreaSingleListSingle">
				{!renameActive ? <>
					<div>{data.name}</div>
					{data.subAreaProjects.map((subEle, i) => {
						return subEle.name !== 'log' ? <div key={i} className="subAreaProject">{subEle.name}</div> : null
					})}
					</> : null}
				{renameActive ? <input autoFocus type="text" className="edit-task-title" value={taskname} onChange={e => setTaskname(e.target.value)} onKeyPress={(e) => {if (e.key == 'Enter') updateItemName()}} onBlur={updateItemName}/> : null}
				<div className="rename" onClick={(e) => activateRenameItem(e, data)}>omdøb</div>
				<div className="delete" onClick={(e) => {deleteItem(e, data)}}>slet</div>
			</div>
		</Link>
		)
}

export default AreaSingleListSingle